import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import {
  ApiOnboarding
} from '@/shared/api';
import {
  ConfirmToken,
  OnboardingApplicationItem,
  OnboardingAuthLocalstorage,
  OnboardingRegistrationRequestData,
  OnboardingSignInRequestData,
  OnboardingStatuses,
  OnboardingUser,
  RefreshToken
} from '@/shared/types/digital-onboarding.types';
import authBrowserStore from '@/shared/utils/auth-browser-store';
import { firebaseLogEvent } from '@/shared/utils/functions/firebase';

export const useOnboardingStore = defineStore(
  'onboarding-auth',
  () => {
    let onboardingLocalStorage: OnboardingAuthLocalstorage | null = null;
    const onboardingAuth = localStorage.getItem('onboarding-auth');
    onboardingLocalStorage = JSON.parse(onboardingAuth || '{}');

    const accessToken = ref<string>(authBrowserStore.onboardingAccessToken);
    const refreshToken = ref<string>(authBrowserStore.onboardingRefreshToken);
    const user = ref<OnboardingUser | null>(onboardingLocalStorage?.user || null);
    const isSignOutManualy = ref<boolean>(false);
    const applicationListData = ref<OnboardingApplicationItem[] | null>(onboardingLocalStorage?.applicationListData || null);
    const selectedApplication = ref<OnboardingApplicationItem | null>(onboardingLocalStorage?.selectedApplication || null);

    const selectedApplicationId = computed<string>(() => selectedApplication.value?.id || '');

    const isAuth = computed<boolean>(
      () => !isSignOutManualy.value && !!accessToken.value
    );

    const isApplicationReceivedSuccessfully = computed<boolean>(() => !!selectedApplication.value);

    const selectedApplicationState = computed<number>(
      () => selectedApplication.value?.state || OnboardingStatuses.PRE_LOADING
    );

    const applicationList = computed<OnboardingApplicationItem[]>(() => {
      return applicationListData.value || [];
    });

    const signIn = async (formData: OnboardingSignInRequestData) => {
      const result = await ApiOnboarding.signIn(formData);

      const { item, error } = result;

      if (!error) {
        updateAccessToken({
          accessToken: item?.accessToken || '',
          refreshToken: item?.refreshToken || ''
        });

        updateUser({
          userId: item.userId,
          userName: item.userName
        });
      }

      return result;
    };

    const signUp = async (formData: OnboardingRegistrationRequestData) => {
      const result = await ApiOnboarding.signUp(formData);

      const { item, error } = result;

      if (!error) {
        updateAccessToken({
          accessToken: item?.accessToken || '',
          refreshToken: item?.refreshToken || ''
        });

        updateUser({
          userId: item.userId,
          userName: item.userName
        });
      }

      return result;
    };

    const updateUser = (userData: OnboardingUser) => {
      user.value = userData;
    };

    const confirmAuth = async (requestData: ConfirmToken) => {
      const result = await ApiOnboarding.confirmToken(requestData);

      const { item, error } = result;

      if (!error) {
        updateAccessToken({
          accessToken: item?.accessToken || '',
          refreshToken: item?.refreshToken || ''
        });
      }

      return result;
    };

    const confirmBySms = async(requestData: ConfirmToken) => {
      return await confirmAuth(requestData);
    };

    const confirmSignUpBySms = async (requestData: ConfirmToken) => {
      const result = await ApiOnboarding.signUpConfirm(requestData);

      const { item, error } = result;

      if (!error) {
        updateAccessToken({
          accessToken: item?.accessToken || '',
          refreshToken: item?.refreshToken || ''
        });
      }

      return result;
    };

    const tryToRefreshToken = async () => {
      const { item, error } = await ApiOnboarding.refreshToken({
        accessToken: accessToken.value || '',
        refreshToken: refreshToken.value || ''
      });

      !error && updateAccessToken(item);

      return !error;
    };

    const logout = async() => {
      isSignOutManualy.value = true;

      resetBrowserAccessRefreshToken();

      accessToken.value &&
        (await ApiOnboarding.logout());

      resetAccessRefreshToken();

      firebaseLogEvent({
        eventName: 'logout'
      });

      user.value = null;
      selectedApplication.value = null;
      applicationListData.value = null;
      isSignOutManualy.value = false;
    };

    const signOut = logout;

    const updateAccessToken = (tokens: RefreshToken | null): void => {
      if (!tokens) return;

      authBrowserStore.onboardingAccessToken = accessToken.value = tokens.accessToken;
      authBrowserStore.onboardingRefreshToken = refreshToken.value = tokens.refreshToken;
    };

    const resetBrowserAccessRefreshToken = () => {
      authBrowserStore.onboardingAccessToken = '';
      authBrowserStore.onboardingRefreshToken = '';
    };

    const resetAccessRefreshToken = () => {
      accessToken.value = '';
      refreshToken.value = '';
    };

    const getApplicationList = async () => {
      const { items, error } = await ApiOnboarding.fetchDOBApplicationList();

      if (error) return;

      applicationListData.value = items;
    };

    // TODO: возможно нужно будет фетчить пока бек не вернет список
    const getApplicationListWithInterval = async (): Promise<void> => {
      const refetchInterval = 5000;
      let refetchCount = Number.MAX_SAFE_INTEGER;

      const intervalId = setInterval(async () => {
        const { items, error } = await ApiOnboarding.fetchDOBApplicationList();
        refetchCount--;

        if (items.length) {
          applicationListData.value = items;

          if (!selectedApplication.value) {
            [ selectedApplication.value ] = items;
          }

          clearInterval(intervalId);
          return;
        }

        if (refetchCount <= 0) {
          clearInterval(intervalId);
          return;
        }

        error && console.error(error);
      }, refetchInterval);
    };

    async function handleSelectAplication(id: string) {
      selectedApplication.value = applicationListData.value?.find((application) => application.id === id) || null;

      // вместо релоада нужно актуальный статус по заявке стянуть и показть его
      window.location.reload();
    }

    // TODO: refactor this fn
    const getApplicationStatusById = async (
    ) => {
      const { item, error } = await ApiOnboarding.fetchDOBStatusById(selectedApplicationId.value);

      if (error) return;

      if (!!item.id && !!item.state && selectedApplication.value?.state !== item.state) {
        selectedApplication.value = { ...item };

        getApplicationList();
      }
    };

    // TODO: refactor this fn (single responsibility)
    const getApplicationStatusByIdWithInterval = async (
      interval: number = 5000,
      count: number = 1000
    ) => {
      const refetchInterval = interval;
      let refetchCount = count;

      await getApplicationStatusById();

      const intervalId = setInterval(async () => {
        if (refetchCount <= 0 || !selectedApplicationId.value) {
          clearInterval(intervalId);
          return;
        }

        const { item, error } = await ApiOnboarding.fetchDOBStatusById(selectedApplicationId.value);

        refetchCount--;

        if (!!item.id && !!item.state && selectedApplication.value?.state !== item.state) {
          selectedApplication.value = { ...item };

          getApplicationList();
        }

        error && console.error(error);
      }, refetchInterval);
    };

    return {
      user,
      isAuth,
      accessToken,
      refreshToken,
      applicationList,
      selectedApplication,
      isApplicationReceivedSuccessfully,
      selectedApplicationState,
      selectedApplicationId,
      applicationListData,
      signIn,
      signUp,
      signOut,
      updateUser,
      confirmBySms,
      confirmSignUpBySms,
      tryToRefreshToken,
      updateAccessToken,
      getApplicationListWithInterval,
      handleSelectAplication,
      getApplicationList,
      getApplicationStatusById,
      getApplicationStatusByIdWithInterval
    };
  },
  {
    persist: {
      key: 'onboarding-auth',
      storage: window.localStorage,
      paths: [
        'user',
        'selectedApplication',
        'applicationListData'
      ]
    }
  }
);
