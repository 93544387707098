export const onboardingRoutes = [
  {
    path: '/',
    name: 'onboarding',
    meta: {},
    component: () => import('@/pages/onboarding/open-account/OpenAccount.vue')
  },
  {
    path: '/open-account',
    name: 'open-account',
    meta: {},
    component: () => import('@/pages/onboarding/open-account/OpenAccount.vue')
  }
];
