import { scrollToError } from '@/shared/utils/functions';
import BaseForm from '../components/base-form/BaseForm.vue';
import { IClientAccount, IClientAccountFormatted } from '../types/account.type';
import { IFormRef } from '../types/form.type';
import { ISelectPhoneInputOption } from '../types/select.types';
import { countryPhoneCodesList } from './constants/country-phone-codes';
import renderIcon from './render-icon';

export function currencyFormat(number: number | string | null): string {
  return (Number(number) / 100)
    .toLocaleString('ru', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
    .replace(',', '.');
}

export function prettifyCount(num: number | string) {
  const regExp = /(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g;

  const res = num.toString().replace(regExp, '$1 ');
  return res;
}

/**
 * @description: format currency from 123456 to 1 234.56
 * @param currency {number | string | null} - currency in format 123456 (1234.56)
 * @returns {string} - currency in format 1 234.56
 */
export function formatUnsafeCurrency(currency: string | number | null): string {
  if (!currency) return '';

  if (typeof currency === 'number') currency = currency.toString();

  return `${prettifyCount(currency.slice(0, currency.length - 2))},${currency.slice(
    currency.length - 2
  )}`;
}

export function round(num: number | string) {
  return Math.round((+num + Number.EPSILON) * 100) / 100;
}

export function accountFormat(
  account: IClientAccount
): IClientAccountFormatted {
  const formattedAccount: IClientAccountFormatted = {
    ...account,
    dayStartBalanceFormatted: currencyFormat(account.dayStartBalance),
    currentBalanceFormatted: currencyFormat(account.currentBalance),
    dayEndBalanceFormatted: currencyFormat(account.dayEndBalance),
    volumeDebitFormatted: currencyFormat(account.volumeDebit),
    volumeCreditFormatted: currencyFormat(account.volumeCredit)
  };

  if (account.lastOperationDate) {
    formattedAccount.lastOperationDateFormatted = account.lastOperationDate;
  }

  if (account.openDate) {
    formattedAccount.openDateFormatted = account.openDate;
  }

  if (account.closeDate) {
    formattedAccount.closeDateFormatted = account.closeDate;
  }

  return formattedAccount;
}

export function isReceiverAccountNumberCorrect(
  receiverAccount: string | null,
  receiverBranch: string | null | undefined,
  senderAccount: string | null | undefined
) {
  if (!/^\d{20}$/.test(receiverAccount || '')) {
    return false;
  }

  if (!receiverBranch || !senderAccount) {
    return true;
  }

  const bal = receiverAccount?.substring(0, 5);
  const iso = receiverAccount?.substring(5, 8);
  const client = receiverAccount?.substring(9, 17);
  const num = receiverAccount?.substring(17, 20);
  const s = '' + receiverBranch + bal + iso + client + num;
  let k = 0;

  for (let n = 0; n < s.length; n++) {
    if (n === s.length - 1) {
      k = k + parseInt(s.substring(n, n + 1)) * 9;
    } else {
      k = k + Number(s.substring(n, n + 1)) * Number(s.substring(n + 1, n + 2));
    }
  }

  k = k % 11;

  switch (k) {
  case 0:
    k = 9;
    break;
  case 1:
    k = 0;
    break;
  default:
    k = 11 - k;
  }

  return (
    receiverAccount !== senderAccount &&
    iso === senderAccount?.substring(5, 8) &&
    receiverAccount === '' + bal + iso + k + client + num
  );
}

export function isInnCorrect(inn: string) {
  if (!/^\d{9}$/.test(inn) || inn === '777777777') {
    return false;
  }

  try {
    const innArr = inn.split('').map((num) => +num);
    const innFloat =
      (innArr[0] * 37 +
        innArr[1] * 29 +
        innArr[2] * 23 +
        innArr[3] * 19 +
        innArr[4] * 17 +
        innArr[5] * 13 +
        innArr[6] * 7 +
        innArr[7] * 3) /
      11;
    const key = Math.trunc(9 - (innFloat - Math.trunc(innFloat)) * 9);
    return key === innArr[8];
  } catch (e) {
    return false;
  }
}

export function isPinflCorrect(pinfl: string) {
  if (/^\d{14}$/.test(pinfl)) {
    const gen = Number(pinfl[0]);
    let century = '19';

    if (gen <= 2) {
      century = '18';
    } else if (gen >= 5) {
      century = '20';
    }

    const birthMonth = pinfl.substring(3, 5);
    const birthDay = pinfl.substring(1, 3);
    const birthYear = century + pinfl.substring(5, 7);
    const birthDate = birthMonth + '/' + birthDay + '/' + birthYear;

    if (
      new Date(birthDate).toLocaleDateString('en', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }) === birthDate &&
      gen > 0 &&
      gen < 7
    ) {
      let total = 0;

      for (let i = 0; i <= pinfl.length - 2; i++) {
        const remainder = i % 3;
        let weightDigit = 0;

        if (remainder === 0) {
          weightDigit = 7;
        } else if (remainder === 1) {
          weightDigit = 3;
        } else {
          weightDigit = 1;
        }

        total = total + Number(pinfl[i]) * weightDigit;
      }

      if (total % 10 === Number(pinfl[pinfl.length - 1])) {
        return true;
      }
    } else {
      return false;
    }
  }

  return false;
}

export function isCardNumberCorrect(cardNumber: string) {
  const num = String(cardNumber).replace(/\D/g, '');
  let ch = 0;

  if (num === '') {
    return false;
  }

  for (let i = 0; i < num.length; i++) {
    let n: number = parseInt(num[i], 10);
    ch += 0 === i % 2 && 9 < (n *= 2) ? n - 9 : n;
  }

  return 0 === ch % 10;
}

export async function formValidate(
  form: IFormRef | InstanceType<typeof BaseForm> | null
): Promise<boolean> {
  if (!form) return false;
  const hasErrors = Array.isArray(await form?.validate()?.catch((e: []) => e));

  if (hasErrors) {
    scrollToError(form.$el);
  }

  return new Promise((res) => {
    res(hasErrors);
  });
}

export function validateFieldByKey(
  formRef: IFormRef | InstanceType<typeof BaseForm> | null,
  key: string
) {
  setTimeout(() => {
    formRef?.validate(
      () => {},
      (rule) => {
        return rule?.key === key;
      }
    );
  });
}

export const formatTextInTextareaStrict = (
  textAreaValue: string,
  charactersPerLine: number[]
) => {
  const textAreaValueInArray = textAreaValue
    .replace(' ', '')
    .split('')
    .filter((char) => char !== '\n');
  let previousDeliminator = 0;
  for (let row = 0; row < charactersPerLine.length; row++) {
    if (textAreaValueInArray[previousDeliminator + charactersPerLine[row]]) {
      textAreaValueInArray.splice(
        previousDeliminator + charactersPerLine[row],
        0,
        '\n'
      );
    }
    previousDeliminator = previousDeliminator + charactersPerLine[row] + 1;
  }
  textAreaValueInArray.splice(previousDeliminator);
  return textAreaValueInArray.join('');
};

export function formatPhoneNumber(phone: string) {
  if (!phone) return phone;

  if (phone.length === 9) return phone.replace(/(\d{2})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4');

  return phone.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
}

export function getCountryPhoneCodeItem(
  phone: string
): Omit<ISelectPhoneInputOption, 'label' | 'value'> {
  const ruISOCode = '643';

  const foundItem = countryPhoneCodesList.find((el) => {
    const res = phone.startsWith(el.label);
    const kazPrefixRangeList = [ '6', '7' ];

    const onePrefixRange = phone.slice(2, 3);

    if (
      res &&
      el.iso?.code === ruISOCode &&
      kazPrefixRangeList.includes(onePrefixRange)
    ) {
      return false;
    }

    return res;
  }) || {
    flag: renderIcon('uzb-flag'),
    mask: ''
  };

  return foundItem;
}

export function removeNullFields(item: Record<string, unknown>) {
  return Object.fromEntries(Object.entries(item).filter(([ , v ]) => v !== null));
}

export function capitalizeFirstLetter(value: string): string {
  return value.charAt(0).toUpperCase() + value.slice(1);
}
