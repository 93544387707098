import type { RouteRecordRaw } from 'vue-router';
import { ROUTE_KEY_TYPE } from '@/shared/utils/constants/common';

export default [
  {
    path: '/',
    name: 'main',
    meta: {
      keyName: ROUTE_KEY_TYPE.WORK_WITH_DOCUMENTS
    },
    component: () => import('../../pages/main/MainPage.vue')
  }
] as RouteRecordRaw[];
