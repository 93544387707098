export const COLORS = {
  // Surface colors
  surfacePrimary: '#ffffff',
  surfaceSecondary: '#ffffff',

  // Container colors
  containerPrimary: '#ffffff',
  containerSecondary: '#f3f4f6',

  // Text colors
  textPrimary: '#101010',
  textSecondary: '#808080',
  textTertiary: '#999999',
  textQuaternary: '#ffffff',
  textDisabled: '#c2c2c2',

  // Element colors
  elementsPrimary: '#101010',
  elementsSecondary: '#ffffff',
  elementsTertiary: '#f3f4f6',
  elementsQuaternary: '#e7e8ea',
  elementsQuinary: '#c5c7ca',
  elementsSenary: '#4e4d4d',

  // Static colors
  staticBlack: '#101010',
  staticWhite: '#ffffff',
  staticRed: '#f24835',
  staticGreen: '#09b849',
  staticError: '#cb0000',
  staticPurple: '#7000ff',
  staticYellow: '#f9de6d',
  staticOrange: '#F48C2C',

  // Brand colors
  brand4: '#54B8F9',
  brand150: '#F8F2FF',
  brand1100: '#EAE2FF',
  brand1300: '#BBA2FD'
};
