<script setup lang="ts">
import { computed, onBeforeUnmount, provide } from 'vue';
import { useI18n } from 'vue-i18n';
import { useWindowSize } from '@vueuse/core';
import {
  dateEnUS,
  dateRuRU,
  enUS,
  NConfigProvider,
  NDateLocale,
  NDialogProvider,
  NLocale,
  NNotificationProvider,
  ruRU
} from 'naive-ui';
import { uzUz } from '@/shared/locales/naive-locales';
import { themeOverrides } from '@/shared/utils/constants/naive-overrides';
import { useCommonStore } from '@/app/store/commonStore';
import { BREAKPOINTS } from './shared/utils/constants/breakpoints';
import { firebaseLogEvent } from './shared/utils/functions/firebase';
import VersionProvider from './app/providers/VersionProvider.vue';

interface ILocales {
  en: NLocale;
  ru: NLocale;
  uz: NLocale;
}

interface IDateLocales {
  en: NDateLocale;
  ru: NDateLocale;
}

const { locale } = useI18n();

// app locale for naive ui
const appLocale = computed<NLocale>(() => {
  const locales: ILocales = {
    en: enUS,
    ru: ruRU,
    uz: uzUz
  };

  return locales[locale.value as keyof ILocales];
});

const dateLocale = computed<NDateLocale>(() => {
  const locales: IDateLocales = {
    en: dateEnUS,
    ru: dateRuRU
    //uz: dateUz,
  };

  return locales[locale.value as keyof IDateLocales];
});

const { md, xl, xxl } = BREAKPOINTS;
const { width, height } = useWindowSize();
const isSmallDesktop = computed(() => width.value > xl && width.value < xxl);
const isMobile = computed(() => width.value < md);

provide('isSmallDesktop', isSmallDesktop);
provide('isMobile', isMobile);

firebaseLogEvent({
  eventName: 'app_open',
  event_params: {
    ScreenSizeX: width.value,
    ScreenSizeY: height.value
  }
});

onBeforeUnmount(() => {
  firebaseLogEvent({
    eventName: 'app_close'
  });
});
</script>

<template>
  <n-config-provider
    :theme-overrides="themeOverrides"
    :locale="appLocale"
    :date-locale="dateLocale"
  >
    <n-dialog-provider>
      <n-notification-provider
        keep-alive-on-hover
        :max="3"
      >
        <VersionProvider :version="useCommonStore().appVersion">
          <router-view />
        </VersionProvider>
      </n-notification-provider>
    </n-dialog-provider>
  </n-config-provider>
</template>

<style scoped></style>
